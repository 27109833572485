/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import {
    validateConsultationMessage,
    validateDiscountCode,
    validateEmailAddress,
    validateIsoDateString,
    validatePersonalName,
    validatePhoneNumber,
    validateRequestDeclaration,
    validateURLValue,
} from '../validator';
import { PatientNotificationTypeSchema, USER_VERIFICATION_CODE_LIMIT } from '../constants';
import { maybe } from '../utils/runtypes';

export const PersonalNameSchema = r.String.withConstraint(validatePersonalName);

export const URLSchema = r.String.withConstraint(validateURLValue);

export const DateSchema = r.String.withConstraint(validateIsoDateString).Or(r.InstanceOf(Date));

export const CreatedAtTimestampSchema = r.Record({ created_at: DateSchema });

export const TimestampSchema = r.Record({ updated_at: DateSchema }).extend(CreatedAtTimestampSchema.fields);
export type TimeStamps = r.Static<typeof TimestampSchema>;

export const IdEntitySchema = r.Record({ id: r.String });
export type IdEntity = r.Static<typeof IdEntitySchema>;

export const PhoneNumberSchema = r.String.withConstraint(validatePhoneNumber);

export const DiscountCodeSchema = r.String.withConstraint(validateDiscountCode);

export const VerificationCodeSchema = r.String.withConstraint(
    (code) =>
        code.trim().length === USER_VERIFICATION_CODE_LIMIT ||
        'Invalid verification code. Please check your email for the code and try again.',
);

export const DigitalConsultationSchema = r.Record({
    response: r.String,
    question: r.String,
});
export type DigitalConsultation = r.Static<typeof DigitalConsultationSchema>;

export const RequestDeclarationSchema = r.String.withConstraint(validateRequestDeclaration);

export const EmailAddressSchema = r.String.withConstraint(validateEmailAddress);

export const ConsultationMessageSchema = r.String.withConstraint(validateConsultationMessage);

export const ERxPadExportProfileSchema = r.Array(r.String);

export const ConsultationNotificationDatabaseRecordSchema = r
    .Record({
        doctor_id: r.String,
        doctor_name: r.String,
        is_notification_active: r.Boolean,
        google_meet_name: maybe(r.String),
        consultation_url: maybe(URLSchema),
        notification_type: PatientNotificationTypeSchema,
    })
    .extend(CreatedAtTimestampSchema.fields);
export type ConsultationNotificationDatabaseRecord = r.Static<typeof ConsultationNotificationDatabaseRecordSchema>;

export const ConsultationNotificationSchema = ConsultationNotificationDatabaseRecordSchema.omit('google_meet_name');
export type ConsultationNotification = r.Static<typeof ConsultationNotificationSchema>;

export const MedicalRequestContactNumberSchema = r.Record({
    phone: PhoneNumberSchema,
});

export const MedicalProfilePractitionerSchema = r.Record({
    practitioner_name: maybe(r.String),
    practitioner_email: maybe(EmailAddressSchema),
});
export type MedicalProfilePractitioner = r.Static<typeof MedicalProfilePractitionerSchema>;
