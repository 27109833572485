/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export enum ConsultationRoutes {
    Auth = 'auth',
    Patient = 'patient',
}

export enum PatientDashboardRoutes {
    Profile = 'profile',
    Pathology = 'pathology',
    Referrals = 'referrals',
    Onboarding = 'onboarding',
    Telehealth = 'telehealth',
    Prescriptions = 'prescriptions',
    NicotineVaping = 'nicotine-vaping',
    MedicalProfiles = 'medical-profiles',
    MedicalRequests = 'medical-requests',
    MedicalCertificates = 'medical-certificates',
    ReferralConsultation = 'referrals/consultation',
    PathologyConsultation = 'pathology/consultation',
    TelehealthConsultation = 'telehealth/consultation',
    PrescriptionConsultation = 'prescriptions/consultation',
    MentalHealthConsultation = 'mental-health/consultation',
    NicotineVapingConsultation = 'nicotine-vaping/consultation',
    MedicalCannabisConsultation = 'medical-cannabis/consultation',
    WeightManagementConsultation = 'weight-management/consultation',
    MedicalCertificatesConsultation = 'medical-certificates/consultation',
    Checkout = 'checkout',
}
