/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { PathologyRequestSchema } from '../pathology';
import { TelehealthRequestSchema } from '../telehealth';
import { MedicalCertificateRequestSchema } from './certificate';
import {
    CommunicationTypeSchema,
    GenderSchema,
    InvoicePaymentStatusSchema,
    MedicalRequestScriptDocumentTypeSchema,
    MedicalRequestSeverityLevelSchema,
    MedicalRequestStatusSchema,
    MedicalRequestTypeSchema,
} from '../../constants';
import {
    CreatedAtTimestampSchema,
    DateSchema,
    EmailAddressSchema,
    IdEntitySchema,
    ConsultationNotificationDatabaseRecordSchema,
    TimestampSchema,
    URLSchema,
} from '..';
import { validateMedicalRequestFlagMessage } from '../../validator';
import { maybe } from '../../utils/runtypes';
import { SpecialistReferralRequestSchema } from '../referral';
import { MedicalProfileResourceSchema } from './profile';
import { ConsultationFileResourceSchema, MedicalRequestFileDatabaseRecordSchema } from '../file';
import { PrescriptionRequestSchema } from '../prescription/consultation';
import { WeightManagementRequestSchema } from '../weight-management';
import { NicotineVapingRequestSchema } from '../nicotine-vaping';
import { SkincareClinicRequestSchema } from '../skincare';
import { MentalHealthRequestSchema } from '../mental-health';
import { MedicalCannabisRequestSchema } from '../medical-cannabis';

export const MedicalRequestFlagSchema = r.Record({
    severity: MedicalRequestSeverityLevelSchema,
    message: r.String.withConstraint(validateMedicalRequestFlagMessage),
});
export type MedicalRequestFlag = r.Static<typeof MedicalRequestFlagSchema>;

const MedicalRequestContributorDoctorSchema = r.Record({
    doctor_name: r.String,
    created_at: DateSchema,
});

export const MedicalRequestFlagResourceSchema = MedicalRequestFlagSchema.extend(
    MedicalRequestContributorDoctorSchema.fields,
).extend(IdEntitySchema.fields);
export type MedicalRequestFlagResource = r.Static<typeof MedicalRequestFlagResourceSchema>;

export const MedicalRequestNoteSchema = r.Record({
    message: r.String,
});
export type MedicalRequestNote = r.Static<typeof MedicalRequestNoteSchema>;

export const MedicalRequestNoteResourceSchema = MedicalRequestNoteSchema.extend(
    MedicalRequestContributorDoctorSchema.fields,
);
export type MedicalRequestNoteResource = r.Static<typeof MedicalRequestNoteResourceSchema>;

const MedicalRequestHistorySchema = r
    .Record({
        log: r.String,
        note: maybe(r.String),
        doctor_name: r.String,
        status: MedicalRequestStatusSchema,
    })
    .extend(CreatedAtTimestampSchema.fields);
export type MedicalRequestHistory = r.Static<typeof MedicalRequestHistorySchema>;

export const MedicalCommunicationHistorySchema = r
    .Record({
        doctor_name: r.String,
        communication_type: CommunicationTypeSchema,
    })
    .extend(CreatedAtTimestampSchema.fields);
export type MedicalCommunicationHistory = r.Static<typeof MedicalCommunicationHistorySchema>;

export const MedicalRequestServiceSchema = PathologyRequestSchema.Or(SpecialistReferralRequestSchema)
    .Or(TelehealthRequestSchema)
    .Or(MentalHealthRequestSchema)
    .Or(MedicalCertificateRequestSchema)
    .Or(WeightManagementRequestSchema)
    .Or(NicotineVapingRequestSchema)
    .Or(SkincareClinicRequestSchema)
    .Or(MedicalCannabisRequestSchema)
    .Or(PrescriptionRequestSchema);
export type MedicalRequestService = r.Static<typeof MedicalRequestServiceSchema>;

export const MedicalRequestCheckoutSchema = r.Record({
    signature: r.String,
    request_reason: r.String,
    medical_profile_id: r.String,
    service: MedicalRequestServiceSchema,
});
export type MedicalRequestCheckout = r.Static<typeof MedicalRequestCheckoutSchema>;

export const PatientPrescriptionDocumentSchema = r.Record({
    document: URLSchema,
    document_type: MedicalRequestScriptDocumentTypeSchema,
});
export type PatientPrescriptionDocument = r.Static<typeof PatientPrescriptionDocumentSchema>;

export const PatientMedicalRequestSchema = r
    .Record({
        title: r.String,
        category: r.String,
        patient_name: r.String,
        invoice: maybe(r.String),
        patient_gender: GenderSchema,
        patient_date_of_birth: DateSchema,
        status: MedicalRequestStatusSchema,
        prescriptions: r.Array(PatientPrescriptionDocumentSchema),
    })
    .extend(CreatedAtTimestampSchema.fields)
    .extend(IdEntitySchema.fields);
export type PatientMedicalRequest = r.Static<typeof PatientMedicalRequestSchema>;

export const GetPatientMedicalRequestsSchema = r.Array(PatientMedicalRequestSchema);

export const MedicalRequestSchema = r.Record({
    request_reason: r.String,
    has_wrong_phone_number: r.Boolean,
    status: MedicalRequestStatusSchema,
    prescription_link: maybe(URLSchema),
    service: MedicalRequestServiceSchema,
    history: r.Array(MedicalRequestHistorySchema),
    flags: r.Array(MedicalRequestFlagResourceSchema),
    notes: r.Array(MedicalRequestNoteResourceSchema),
    files: r.Array(MedicalRequestFileDatabaseRecordSchema),
    communication_history: r.Array(MedicalCommunicationHistorySchema),
});
export type MedicalRequest = r.Static<typeof MedicalRequestSchema>;

const MedicalRequestDoctorSchema = r.Record({
    id: r.String,
    name: r.String,
    doctor_pickup_time: DateSchema,
});

export const MedicalRequestScriptDatabaseRecordSchema = r.Record({
    document: r.String,
    document_key: r.String,
    verification_code: r.String,
    document_type: MedicalRequestScriptDocumentTypeSchema,
});
export type MedicalRequestScriptDatabaseRecord = r.Static<typeof MedicalRequestScriptDatabaseRecordSchema>;

export const MedicalRequestDatabaseSchema = r
    .Record({
        id: r.Number,
        signature: r.String,
        patient_id: r.String,
        reference_id: r.String,
        request_reason: r.String,
        invoice: maybe(r.String),
        email: EmailAddressSchema,
        request_type: MedicalRequestTypeSchema,
        doctor: maybe(MedicalRequestDoctorSchema),
        prescriptions: r.Array(MedicalRequestScriptDatabaseRecordSchema),
        payment_status: InvoicePaymentStatusSchema,
        payment_currency: r.String,
        payment_amount: r.String,
        medical_profile: MedicalProfileResourceSchema,
        consultation_notifications: r.Array(ConsultationNotificationDatabaseRecordSchema),
    })
    .extend(MedicalRequestSchema.fields)
    .extend(TimestampSchema.fields);
export type MedicalRequestDatabaseRecord = r.Static<typeof MedicalRequestDatabaseSchema>;

export const MedicalRequestResourceSchema = r
    .Record({
        files: r.Array(ConsultationFileResourceSchema),
    })
    .extend(MedicalRequestSchema.omit('files').fields)
    .extend(CreatedAtTimestampSchema.fields)
    .extend(IdEntitySchema.fields)
    .extend(MedicalRequestDatabaseSchema.pick('request_type', 'consultation_notifications').fields);
export type MedicalRequestResource = r.Static<typeof MedicalRequestResourceSchema>;
