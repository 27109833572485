/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export enum PaymentMerchant {
    Stripe = 'Stripe',
    Paypal = 'Paypal',
    Invoice = 'Invoice',
}
export const PaymentMerchantSchema = runtypeFromEnum(PaymentMerchant);

export enum ServiceRequestType {
    Pathology = 'Pathology',
    Telehealth = 'Telehealth',
    Prescription = 'Prescription',
    MentalHealth = 'Mental Health',
    SpecialistReferral = 'Referral',
    NicotineVaping = 'Nicotine Vaping',
    SkincareClinic = 'Skincare Clinic',
    WeightManagement = 'Weight Management',
    MedicalCertificate = 'Medical Certificate',
    AlternativeMedicine = 'Alternative Medicine',
}
